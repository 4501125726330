export const agent_type = {
    INSURER: 1,
    BROKER: 2,
}

export const crud_state = {
    LIST: 1,
    EDIT: 2,
    NEW: 3,
    DEL: 4,
}