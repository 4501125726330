var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","offset-md":"6"}},[_c('v-card',[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"z-index":"1"},attrs:{"color":"blue","fab":!_vm.addBtnTxt,"dark":"","small":"","absolute":"","top":!_vm.addBtnTxt,"right":"","disabled":_vm.disableAdd}},[(_vm.addBtnTxt)?_c('span',{on:{"click":_vm.add_item}},[_vm._v(_vm._s(_vm.addBtnTxt))]):_c('v-icon',{on:{"click":_vm.add_item}},[_vm._v("mdi-plus")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-card',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-heading, list-item-two-line, image"}},'v-skeleton-loader',_vm.$attrs,false))],1):_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.searchLoading)?_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-heading, list-item-two-line, image"}},'v-skeleton-loader',_vm.$attrs,false)):_c('v-data-table',{staticClass:"elevation-1",attrs:{"no-data-text":("No " + _vm.url_fragment + " found."),"headers":_vm.headers,"items":_vm.data_items,"item-key":"id","single-expand":false,"expanded":_vm.expanded,"show-expand":!_vm.hideExpand,"search":_vm.search,"options":_vm.paginationOptions,"server-items-length":_vm.totalCount,"footer-props":_vm.footerOptions},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status ? 'green' : 'grey'}},[_vm._v("mdi-circle")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showEmailIcon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.emialItem(item)}}},[_vm._v("mdi-email")]):_vm._e(),(_vm.showDownloadIcon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v("mdi-arrow-down-bold-circle")]):_vm._e(),(!_vm.hideEditIcon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit_item(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(!_vm.hideDelIcon)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.show_del_dialog(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_vm._l((_vm.expanded_headers),function(expanded_header,index){return [_c('v-col',{key:index + '_' + expanded_header.text,staticClass:"col-12 col-sm-6 col-md-2"},[_vm._v(" "+_vm._s(expanded_header.text)+" ")]),_c('v-col',{key:index + '_' + expanded_header.value,staticClass:"col-12 col-sm-6 col-md-2"},[(
                            item[expanded_header.value] === true ||
                              item[expanded_header.value] === false
                          )?[_c('v-icon',{attrs:{"color":item[expanded_header.value] ? 'green' : 'grey'}},[_vm._v(_vm._s(item[expanded_header.value] ? "mdi-check" : "mdi-close"))])]:[_vm._v(" "+_vm._s(_vm._f("dateFiltered")(item[expanded_header.value]))+" ")]],2)]})],2)],1)],1)]}}])})],1)],1)],1)],1),(_vm.item_to_delete)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete")]),_c('v-card-text',[_vm._v("Are you sure you want to delete `"+_vm._s(_vm.item_to_delete.name)+"`?")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.del_item()}}},[_vm._v("Delete")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")])],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_msg)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }