<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="6" offset-md="6">
          <v-card>
            <v-fab-transition>
              <v-btn
                style="z-index: 1"
                color="blue"
                :fab="!addBtnTxt"
                dark
                small
                absolute
                :top="!addBtnTxt"
                right
                :disabled="disableAdd"
              >
                <span @click="add_item" v-if="addBtnTxt">{{ addBtnTxt }}</span>
                <v-icon v-else @click="add_item">mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card v-if="loading">
            <v-skeleton-loader
              v-bind="$attrs"
              type="table-heading, list-item-two-line, image"
            ></v-skeleton-loader>
          </v-card>
          <v-card v-else>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-skeleton-loader
              v-bind="$attrs"
              v-if="searchLoading"
              type="table-heading, list-item-two-line, image"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :no-data-text="`No ${url_fragment} found.`"
              :headers="headers"
              :items="data_items"
              item-key="id"
              :single-expand="false"
              :expanded.sync="expanded"
              :show-expand="!hideExpand"
              :search="search"
              :options.sync="paginationOptions"
              :server-items-length="totalCount"
              class="elevation-1"
              :footer-props="footerOptions"
            >
              <template :v-if="item.status" v-slot:item.status="{ item }">
                <v-icon :color="item.status ? 'green' : 'grey'"
                  >mdi-circle</v-icon
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showEmailIcon"
                  small
                  class="mr-2"
                  @click="emialItem(item)"
                  >mdi-email</v-icon
                >
                <v-icon
                  v-if="showDownloadIcon"
                  small
                  class="mr-2"
                  @click="downloadItem(item)"
                  >mdi-arrow-down-bold-circle</v-icon
                >
                <v-icon
                  v-if="!hideEditIcon"
                  small
                  class="mr-2"
                  @click="edit_item(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon v-if="!hideDelIcon" small @click="show_del_dialog(item)"
                  >mdi-delete</v-icon
                >
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container fluid>
                    <v-row>
                      <template
                        v-for="(expanded_header, index) in expanded_headers"
                      >
                        <v-col
                          class="col-12 col-sm-6 col-md-2"
                          :key="index + '_' + expanded_header.text"
                        >
                          {{ expanded_header.text }}
                        </v-col>
                        <v-col
                          class="col-12 col-sm-6 col-md-2"
                          :key="index + '_' + expanded_header.value"
                        >
                          <template
                            v-if="
                              item[expanded_header.value] === true ||
                                item[expanded_header.value] === false
                            "
                          >
                            <v-icon
                              :color="
                                item[expanded_header.value] ? 'green' : 'grey'
                              "
                              >{{
                                item[expanded_header.value]
                                  ? "mdi-check"
                                  : "mdi-close"
                              }}</v-icon
                            >
                          </template>
                          <template v-else>
                            {{ item[expanded_header.value] | dateFiltered }}
                          </template>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogDelete" v-if="item_to_delete" max-width="500px">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text
          >Are you sure you want to delete `{{
            item_to_delete.name
          }}`?</v-card-text
        >
        <v-card-actions>
          <v-btn color="error" @click="del_item()">Delete</v-btn>
          <v-btn color="primary" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ snackbar_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BaseService from "@/services/base.service.js";
import moment from "moment";
import { debounce } from "@/helpers/basic_helper";

export default {
  name: "SimpleTable",

  props: [
    "headers",
    "items",
    "expanded_headers",
    "url_fragment", // fragment of url, for api call eg brokers, or templates
    "hideEditIcon",
    "loading",
    "hideDelIcon",
    "totalCount",
    "disableAdd",
    "showEmailIcon",
    "hideExpand",
    "showDownloadIcon",
    "addBtnTxt",
    "searchLoading",
  ],

  data() {
    return {
      // singleSelect: false,
      // selected: [],
      search: "",
      expanded: [],
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      footerOptions: {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 15, 30, 50, 75, 100],
      },
      data_items: [],
      item_to_delete: null,
      dialogDelete: false,
      snackbar: false,
      snackbar_timeout: 3000,
      snackbar_msg: "",
    };
  },

  methods: {
    show_del_dialog(item) {
      this.item_to_delete = item;
      this.dialogDelete = !this.dialogDelete;
    },

    async del_item() {
      let service = new BaseService(this.url_fragment);
      let res = await service.delete(this.item_to_delete.id);
      if (res && res.status === 200) {
        const index = this.items.indexOf(this.item_to_delete);
        this.items.splice(index, 1);
        this.dialogDelete = false;
        this.snackbar_msg = `Deleted ${this.item_to_delete.name} successfully`;
      } else if (res && res.status === 401) {
        this.snackbar_msg = `Error, permission issues. Try logging back in.`;
      } else {
        this.snackbar_msg = `Error deleting ${this.item_to_delete.name}`;
      }
      this.snackbar = true;
    },

    edit_item(item) {
      this.$emit("edit_item_click", item.id);
    },

    emialItem(item) {
      this.$emit("sendEmail", item);
    },

    downloadItem(item) {
      this.$emit("downloadItem", item);
    },

    add_item() {
      this.$emit("add_item_click");
    },
  },
  filters: {
    dateFiltered(val) {
      let date = moment(val);
      if (val && date.isValid() && val.length > 10) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return val;
      }
    },
  },
  watch: {
    items: function(newValue) {
      this.data_items = newValue;
    },
    search: debounce(function(val) {
      if (val.length > 2) {
        this.$emit("hitSearch", val);
      } else if (val.length === 0) {
        this.$emit("hitSearch", "");
      }
    }, 500),
    paginationOptions(newVal, oldVal) {
      if (
        newVal.itemsPerPage !== oldVal.itemsPerPage ||
        newVal.page !== oldVal.page
      ) {
        this.$emit("hitPagination", newVal);
      }
    },
  },
  mounted() {
    this.data_items = this.items;
  },
};
</script>

<style lang="scss">
.v-application--is-ltr,
.v-data-footer__select,
.v-select {
  min-width: 65px !important;
}
</style>
